<template>
  <v-stepper v-model="steps">
    <v-stepper-header>
      <v-stepper-step :complete="steps > 1" step="1">
        {{ this.$t("config_inventory") }}
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="steps > 2" step="2">
        {{ this.$t("add_product_gap_reason") }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1" class="grey lighten-4">
        <v-card class="mb-12">
          <v-card-text>
            <v-form ref="form" v-model="is_valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :disabled="disable_inputs"
                    dense
                    hide-details="auto"
                    outlined
                    :label="$t('hub_*')"
                    v-model="inventory.hub_id"
                    :items="listHubs"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :disabled="disable_inputs"
                    dense
                    hide-details="auto"
                    outlined
                    :label="$t('inventory_types_*')"
                    v-model="inventory.type"
                    :items="invTypesList"
                    item-value="id"
                    item-text="name"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    :disabled="disable_inputs"
                    dense
                    outlined
                    v-model="inventory.category_parent_id"
                    :items="rootCategories"
                    item-value="id"
                    item-text="name"
                    :label="$t('category_*')"
                    :loading="isLoadingCategories"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="3">
                  <v-autocomplete
                    :disabled="disable_inputs"
                    dense
                    outlined
                    v-model="inventory.category_id"
                    :items="leafCategories"
                    item-value="id"
                    item-text="name"
                    :label="$t('sub_category_*')"
                    :loading="isLoadingCategories"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="5" sm="3" md="3">
                  <v-btn
                    color="primary"
                    block
                    :disabled="inventory.id !== undefined"
                    @click="saveInventory"
                  >
                    {{ $t("create_inventory") }}</v-btn
                  >
                </v-col>
                <!--
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    disabled
                    dense
                    outlined
                    :label="$t('tracking_number')"
                    v-model="inventory.tracking_number"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-btn
                    class="ms-4"
                    color="primary"
                    :disabled="inventory.id !== undefined"
                    @click="saveInventory"
                  >
                    {{ $t("create") }}</v-btn
                  >
                </v-col>
                -->
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <!-- tabs all product / in inventory -->
        <v-card class="mx-auto">
          <v-toolbar dark tabs flat color="#6200EA" class="hidden-xs-only">
            <template>
              <v-tabs v-model="tabs" align-with-title>
                <v-tab href="#all"> PRODUCT LIST </v-tab>
                <v-tab href="#inventory" @click="loadInventoryProducts()">
                  ITEMS IN INVENTORY
                </v-tab>
                <v-tabs-slider color="pink"></v-tabs-slider>
              </v-tabs>
            </template>

            <delete-inventory-item-dialog
              v-if="$admin.can('inventory-delete')"
            ></delete-inventory-item-dialog>
          </v-toolbar>

          <v-tabs-items v-model="tabs">
            <v-tab-item value="all">
              <v-container v-if="!allListItems">
                <v-card class="mb-12 no-shadow">
                  <div class="please_select_options">
                    {{ $t("please_select_options") }}
                  </div>
                </v-card>
              </v-container>
              <v-container v-else id="regular-tables" fluid>
                <v-data-table
                  :headers="headersAll"
                  :items="listProducts"
                  :loading="isLoadingProducts"
                  :server-items-length="productsMeta.totalItems"
                  :options.sync="all_products_options"
                  :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  item-key="id"
                  show-expand
                  class="elevation-2"
                >
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <table class="table_inventory">
                        <tr class="header">
                          <th>{{ $t("batch_number") }}</th>
                          <th>{{ $t("batch_expiration_date") }}</th>
                          <th>{{ $t("batch_quantity") }}</th>
                        </tr>
                        <tr
                          v-for="(next, i) in item.active_lots"
                          :key="i"
                          class="elements"
                        >
                          <td>{{ next.lot_number }}</td>
                          <td>{{ next.expiration_date }}</td>
                          <td>{{ next.qte_in_lot }}</td>
                        </tr>
                      </table>
                    </td>
                  </template>
                  <template v-slot:item.image="{ item }">
                    <v-img
                      v-if="item.image"
                      :src="item.image.url"
                      max-height="50"
                      max-width="50"
                    />
                    <v-img
                      v-else
                      src="@/assets/product.png"
                      max-height="50"
                      max-width="50"
                    />
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      color="primary"
                      v-if="$admin.can('inventory-update')"
                      small
                      class="mr-2"
                      @click="addItem(item)"
                    >
                      add
                    </v-btn>
                  </template>
                  <template v-slot:no-data>
                    {{ $t("no_data_available") }}
                  </template>
                  <template v-slot:top>
                    <v-row class="pa-4">
                      <v-text-field
                        v-model="all_products_options.search"
                        :label="$t('search')"
                        class=""
                        @keyup.enter="updateProductByEnterKey"
                      ></v-text-field>
                      <v-btn
                        disabled
                        class="ms-4"
                        color="primary"
                        :loading="is_adding_all_products"
                        @click="addAll"
                        >+ Add All</v-btn
                      >
                    </v-row>
                  </template>
                </v-data-table>
              </v-container>
            </v-tab-item>
            <v-tab-item value="inventory">
              <v-data-table
                :headers="headersInventory"
                :items="listInventoryProducts"
                :loading="isLoadingProducts"
                :server-items-length="inventoryProductsMeta.totalItems"
                :options.sync="inventory_products_options"
                :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                show-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <table class="table_inventory">
                      <tr class="header">
                        <th>{{ $t("batch_number") }}</th>
                        <th>{{ $t("batch_expiration_date") }}</th>
                        <th>{{ $t("batch_quantity") }}</th>
                        <th>{{ $t("batch_quantity_real") }}</th>
                      </tr>
                      <tr
                        v-for="(next, i) in item.adjustement_lots"
                        :key="i"
                        class="elements"
                      >
                        <td>{{ next.lot_number }}</td>
                        <td>{{ next.expiration_date }}</td>
                        <td>{{ next.qte_in_lot }}</td>
                        <td>{{ next.adjustment_quantity }}</td>
                      </tr>
                    </table>
                  </td>
                </template>
                <template v-slot:item.image="{ item }">
                  <v-img
                    v-if="item.image"
                    :src="item.image.url"
                    max-height="50"
                    max-width="50"
                  />
                  <v-img
                    v-else
                    src="@/assets/product.png"
                    max-height="50"
                    max-width="50"
                  />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="removeProduct(item)">
                    mdi-delete
                  </v-icon>
                </template>

                <template v-slot:top>
                  <v-row class="pa-4">
                    <v-text-field
                      v-model="all_products_options.search"
                      :label="$t('search')"
                      class=""
                      @keyup.enter="updateProductByEnterKey"
                    ></v-text-field>
                    <v-btn
                      class="ms-4"
                      color="primary"
                      :loading="is_removing_all_products"
                      :disabled="listInventoryProducts.length === 0"
                      @click="removeAll"
                      >- Remove All</v-btn
                    >
                  </v-row>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <!-- end products tabs -->

        <v-container id="regular-tables" fluid tag="section">
          <edit-product-quantity v-if="steps == 1"></edit-product-quantity>
        </v-container>
        <v-btn color="primary" @click="updateQuantities()"> Continue </v-btn>

        <v-btn text @click="returnToList"> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <product-gap-reason-step
          :steps="this.steps"
          :inventory_id="this.inventory.id"
        ></product-gap-reason-step>
        <v-btn color="primary" @click="finish()"> Finish </v-btn>

        <v-btn text @click="steps = 1"> Back </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import "../../../../../src/css/inventory_create.css";

export default {
  mixins: [validationRules],

  async created() {
    await this.$store.dispatch("hubs/list");
    await this.$store.dispatch("categories/listRoots");
    await this.$store.dispatch("inventories/closeForm");
    await this.$store.dispatch("inventories/clearState");
    await this.$store.dispatch("products/closeForm");
  },

  components: {
    ProductGapReasonStep: () =>
      import("./components/steps/ProductReasonStep.vue"),
    editProductQuantity: () =>
      import("./components/dialogs/UpdateInventoryItemQuantity.vue"),
    DeleteInventoryItemDialog: () =>
      import("./components/dialogs/DeleteInventoryItemDialog.vue"),
  },

  computed: {
    ...mapGetters({
      listHubs: "hubs/activeHubs",
      inventory: "inventories/getInventory",
      rootCategories: "categories/listRoots",
      leafCategories: "categories/listLeafs",
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",

      product: "inventories/product",
      listInventoryProducts: "inventories/listProducts",
      inventoryProductsMeta: "inventories/productsMeta",
    }),

    category_parent_id() {
      return this.inventory.category_parent_id;
    },
    inventory_id() {
      return this.inventory.id;
    },
    disable_inputs() {
      return this.inventory.id ? true : false;
    },
  },

  watch: {
    category_parent_id: function (id) {
      this.$store.dispatch("categories/listLeafs", { parent_id: id });
    },

    all_products_options: {
      handler: debounce(async function () {
        this.getAllProducts();
      }, 1000),
      deep: true,
    },

    inventory_products_options: {
      async handler() {
        this.getInventoryProducts();
      },
      deep: true,
    },
  },

  methods: {
    loadInventoryProducts() {
      this.getInventoryProducts();
    },
    async getAllProducts() {
      if (this.inventory.hub_id) {
        this.is_loading_product = true;
        const params = {
          ...this.all_products_options,
          with_stock: true,
          hub_id: this.inventory.hub_id,
          category_id: this.inventory.category_id,
          category_parent_id: this.inventory.category_parent_id,
        };
        await this.$store.dispatch("products/root", params).then(() => {
          this.is_loading_product = false;
          if (
            this.all_products_options.page > (this.productsMeta?.lastPage || 1)
          ) {
            this.all_products_options.page = 1;
          }
        });
      }
    },

    async getInventoryProducts() {
      if (this.inventory_id && this.steps == 1) {
        this.is_loading_product = true;

        await this.$store
          .dispatch("inventories/listProducts", {
            inventory_id: this.inventory_id,
            ...this.inventory_products_options,
          })
          .then(() => {
            this.is_loading_product = false;
          });
      }
    },

    async saveInventory() {
      if (this.$refs.form.validate()) {
        this.allListItems = true;
        try {
          this.is_saving_inventory = true;
          if (this.inventory.id) {
            await this.$store.dispatch("inventories/update", this.inventory);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store
              .dispatch("inventories/add", this.inventory)
              .then(() => this.getAllProducts());

            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          // this.steps = 2;
          this.is_saving_inventory = false;
        } catch (error) {
          this.is_saving_inventory = false;
          console.log(error);
          this.$store.dispatch("alerts/error", error.response.data.message);
          this.formErrors = error.response.data.errors;
        }
      }
    },
    async addItem(item) {
      if (!this.inventory_id) {
        await this.saveInventory();
      }
      item.inventory_id = this.inventory_id;
      await this.$store.dispatch("inventories/addProduct", item).then(() => {
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
      });
    },

    async editItem(item) {
      if (!this.inventory_id) {
        await this.saveInventory();
      }
      item.inventory_id = this.inventory_id;
      if (item.inventory_id)
        await this.$store
          .dispatch("inventories/openEditProduct", item)
          .then(() => this.getInventoryProducts());
    },

    async addAll() {
      this.is_adding_all_products = true;
      if (!this.inventory_id) {
        await this.saveInventory();
      }
      await this.$store
        .dispatch("inventories/addAllProducts", {
          with_stock: true,
          hub_id: this.inventory.hub_id,
          category_id: this.inventory.category_id,
          category_parent_id: this.inventory.category_parent_id,
        })
        .then(() => this.getInventoryProducts());

      this.is_adding_all_products = false;
      this.$store.dispatch("alerts/success", this.$t("created_successfully"));
    },

    removeProduct(item) {
      this.$store.dispatch("inventories/openDeleteInventoryItemDialog", item);
    },

    async removeAll() {
      this.is_removing_all_products = true;

      await this.$store
        .dispatch("inventories/removeAllProducts", {
          hub_id: this.inventory.hub_id,
        })
        .then(() => this.getInventoryProducts());

      this.is_removing_all_products = false;
      this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
    },

    async updateQuantities() {
      try {
        await this.getInventoryProducts();
        await this.$store.dispatch(
          "inventories/checkPhysicalQte",
          this.inventory.id
        );
        this.steps = 2;
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response.data.message);
      }
    },

    async finish() {
      try {
        await this.$store.dispatch(
          "inventories/checkGapReasons",
          this.inventory_id
        );
        await this.$store.dispatch("inventories/closeForm");

        this.$router.push({ name: "inventories.index" });
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response.data.message);
      }
    },

    returnToList() {
      this.$router.push({ name: "inventories.index" });
    },
  },

  data() {
    return {
      allListItems: false,
      singleExpand: false,
      expanded: [],
      steps: 1,
      is_loading_product: false,
      isLoadingCategories: false,
      is_valid: false,
      inventory_products_options: { itemsPerPage: 10 },
      all_products_options: { itemsPerPage: 10 },
      tabs: true,
      is_saving_inventory: false,
      is_adding_all_products: false,
      is_removing_all_products: false,
      invTypesList: [
        { id: "global", name: "Inventaire Globale" },
        { id: "periodic", name: "Inventaire Tournant" },
      ],
      headersAll: [
        {
          text: this.$t("batch"),
          value: "data-table-expand",
        },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("code"),
          align: "start",
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("physical_qte"),
          value: "physical_qte",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
      headersInventory: [
        { text: this.$t("batch"), value: "data-table-expand" },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("code"),
          align: "start",
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },

        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("physical_qte"),
          value: "physical_qte",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
};
</script>